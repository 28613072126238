<template>
  <section class="content">
    <div class="box box-primary" style="padding: 10px; position: relative">
      <div id="root">
        <form name="adminForm" method="post" enctype="multipart/form-data">
          <input
            name="cassielAction"
            value="save"
            type="hidden"
            id="cassielAction"
          />
          <input
            id="cassielId"
            name="cassielId"
            :value="cassielid"
            type="hidden"
          />
          <div class="form-group">
            <label class="text-monospace text-bold text-capitalize"
              >Name <span class="text-danger">*</span></label
            >
            <input
              type="text"
              id="name"
              name="name"
              :readonly="cassielid != ''"
              class="form-control"
              placeholder="Insert Name Here"
              :value="cassielid"
              required
            />
          </div>
          <div style="display: none">
            <label class="text-monospace text-bold text-capitalize"
              >Widget <span class="text-danger">*</span></label
            >
            <input
              id="widget"
              class="form-control"
              :readonly="widget"
              :value="widget"
              required
            />
          </div>
          <div class="form-group">
            <label class="text-monospace text-bold text-capitalize"
              >Entity <span class="text-danger">*</span></label
            >
            <input
              id="entity"
              class="form-control"
              :readonly="entity"
              :value="entity"
              required
            />
          </div>

          <div style="display: flow-root">
            <div class="renderedTemplate">
              <div class="home-page-section">
                <div class="home-page-section-title">
                  {{ entity }}
                </div>
                <draggable
                  class="list-group"
                  :list="list"
                  style="min-height: 650px"
                  :group="entity"
                  @change="checkingItem($event, entity)"
                  v-bind:data-section="entity"
                  :sort="false"
                >
                  <div
                    class="home-page-section-content"
                    v-for="(item, index) in list"
                    :key="index"
                  >
                    <div
                      v-bind:data-type="entity"
                      v-bind:data-index="index"
                      v-bind:id="entity + '_' + index"
                      class="cassiel-dropplace"
                    >
                      <div
                        v-if="item"
                        v-bind:data-type="entity"
                        v-bind:data-index="index"
                        class="cassiel-droped-record"
                      >
                        <div
                          v-if="item.url"
                          @click="removeDataOnCLick(index)"
                          class="cassiel-droped-record-removebtn"
                        >
                          X
                        </div>
                        <span class="home-page-section-input">
                          {{ item.title }}
                        </span>
                        <input
                          class="home-page-input"
                          v-bind:data-url="item.url"
                          v-bind:data-title="item.title"
                          type="hidden"
                          v-bind:value="item.url"
                          v-bind:name="entity + 'Url[]'"
                        />
                        <input
                          class="home-page-section-input"
                          v-bind:data-url="item.url"
                          v-bind:data-title="item.title"
                          type="hidden"
                          v-bind:value="item.title"
                          v-bind:name="entity + 'Title[]'"
                        />
                      </div>
                    </div>
                  </div>
                </draggable>
              </div>
            </div>

            <div
              class="templates"
              id="cassiel-library"
              ref="cassielLibrary"
              style="position: fixed; right: 2.4%"
            >
              <div>List Of Data</div>

              <div class="grid">
                <input
                  id="searchInput"
                  type="text"
                  name="searchInput"
                  ref="searchInput"
                  class="form-control"
                  placeholder="Insert Name Here"
                  value=""
                />
                <button
                  class="btn"
                  style="background: #808080; color: white; font-weight: bold"
                  @click="filterData"
                >
                  Search
                </button>
              </div>
              <div style="display: flex; margin-bottom: 0">
                <div class="square-blue"></div>
                <p class="hint">Pending</p>
                <div class="square-green"></div>
                <p class="hint">Published</p>
                <div class="square-yellow"></div>
                <p class="hint">Modified</p>
              </div>
              <div style="display: flex; color: red; font-size: 14px">
                <small>* Can't insert pending element</small>
              </div>
              <div class="tab-content" id="pills-tabContent">
                <draggable
                  class="list-group"
                  :list="templates"
                  :sort="false"
                  :group="{ name: entity, pull: 'clone', put: false }"
                  draggable=".item"
                >
                  <div
                    :class="
                      templates[index].state === '1' ||
                      templates[index].state === '2'
                        ? 'list-group-item tinted item'
                        : 'list-group-item tinted'
                    "
                    v-bind:data-entity="entity"
                    v-bind:data-index="index"
                    v-for="(template, index) in templates"
                    :key="index"
                    data-limit="1"
                  >
                    <span
                      v-if="template"
                      v-bind:class="'state-' + template.state"
                      v-bind:data-url="template.url"
                      v-bind:data-title="template.title"
                    >
                      {{ template.title }}
                    </span>
                    <input
                      v-bind:data-url="template.url"
                      v-bind:data-title="template.title"
                      type="hidden"
                      v-bind:value="template.url"
                      disabled
                    />
                  </div>
                </draggable>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <span class="required"></span><span> Required Fields</span>
          </div>
          <div class="row">
            <button
              v-on:click="generateSchema"
              type="submit"
              name="cassielAction"
              value="save"
              class="btn btn-primary"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<style>
.required::after {
  content: "*";
  color: red;
}
</style>

<script>
import draggable from "vuedraggable";
export default {
  name: "cassielpool",
  mixins: [],
  data: function () {
    return {
      cassielid: this.$route.query.cassielId,
      entity: "",
      widget: "",
      list: [],
      templates: [],
      mainEntityObject: [],
    };
  },
  components: {
    draggable,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY >= 300) {
        console.log("top:80");
        this.$refs.cassielLibrary.setAttribute(
          "style",
          "position: fixed;  right: 2.4%; top:80px"
        );
      } else {
        console.log("top:" + (300 - window.scrollY));

        this.$refs.cassielLibrary.setAttribute(
          "style",
          "position: fixed;  right: 2.4%; top:" + (300 - window.scrollY) + "px"
        );
      }
    },
    generateSchema: function (e) {
      if (e != undefined) {
        e.preventDefault();
      }
      let $ = require("jquery");
      var bodyFormData = new FormData();
      bodyFormData.append("list", JSON.stringify(this.list));
      bodyFormData.append("cassielId", $("#cassielId").val());
      bodyFormData.append("name", $("#name").val());
      bodyFormData.append("entity", $("#entity").val());
      bodyFormData.append("widget", $("#widget").val());
      bodyFormData.append("cassielAction", "save");
      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }
      this.$http({
        url: process.env.VUE_APP_CMS_BACKEND_BASE_URL + "/submit/pool/pool",
        method: "POST",
        data: bodyFormData,
        headers: headers,
      }).then((response) => {
        console.log(response);
        // $('#cassielId').val(response.data.cassielID);
        this.$swal("Saved!", "Data has been saved successufly.", "success");
      });
    },
    filterData: function (e) {
      let $ = require("jquery");
      if (e != undefined) {
        e.preventDefault();
        // itsFilterEvent = true;
      }
      $(".list-group-item.tinted").css("background-color", "white");

      var bodyFormData = new FormData();
      bodyFormData.append("length", 9);
      bodyFormData.append("draw", 1);
      bodyFormData.append("start", 0);
      // var search = $('#searchInput').val()=== undefined? -1: $('#searchInput').val();
      if ($("#searchInput").val() != undefined && $("#searchInput").val() != "")
        bodyFormData.append("search", $("#searchInput").val());
      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }
      this.$http({
        url:
          process.env.VUE_APP_CMS_BACKEND_BASE_URL +
          "/filters/" +
          this.widget +
          "/" +
          this.entity,
        method: "POST",
        data: bodyFormData,
        headers: headers,
      }).then((response) => {
        for (var i = 0; i < response.data.data.length; i++) {
          if (this.templates[i] == undefined) {
            this.templates[i] = new Object();
          }

          var row = {
            title: response.data.data[i][1],
            url: response.data.data[i][5].replace("/preview", ""),
            state: response.data.data[i][3],
          };
          this.$set(this.templates, i, row);
        }
      });
    },
    checkingItem: function (evt) {
      if (evt.added != undefined) {
        var includes = 0;
        var inputs = document.getElementsByClassName("home-page-input");
        for (var i = 0; i < inputs.length; i++) {
          if (inputs[i].value === this.list[evt.added.newIndex].url) {
            includes++;
            if (includes == 2) {
              break;
            }
          }
        }
        if (includes == 2) {
          this.$delete(this.list, Number(evt.added.newIndex));
          this.$swal("Exists", "This record already exists!", "warning");
        }
      }
    },
    removeDataOnCLick: function (index) {
      this.$delete(this.list, index);
    },
  },
  mounted: function () {
    var bodyFormData = new FormData();
    bodyFormData.append("cassielId", this.cassielid);
    bodyFormData.append("name", this.cassielid);
    const token = this.$store.getters["auth/getToken"];
    let headers = {};
    if (token) {
      headers["X-AUTH-TOKEN"] = token;
    }
    this.$http({
      url:
        process.env.VUE_APP_CMS_BACKEND_BASE_URL +
        "/cassiel-backend-api/pool/getData",
      method: "POST",
      data: bodyFormData,
      headers: headers,
    }).then((response) => {
      this.widget = response.data.widget;
      this.entity = response.data.entity;
      this.list = JSON.parse(response.data.list);
      this.filterData();
    });
  },
};
</script>

<style>
.renderedTemplate {
  width: 55%;
  float: left;
  border: solid 1px black;
  min-height: 670px;
}

.tinted {
  background-color: lightgoldenrodyellow;
}

.templates {
  float: left;
  width: 35%;
  border: 1px solid black;
  background-color: #e9ecef;
}

.nav-item {
  margin-right: 5px;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #212121;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border-bottom: 3px solid teal;
}

.onHover {
  cursor: pointer;
}

.templates div {
  font-size: 24px;
  margin-bottom: 10px;
}

.templates .grid {
  display: flex;
  width: 100%;
}

.templates .grid input {
  margin: auto;
  margin-right: 10px;
}

.templates div div {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: right;
}

#cassiel-library div div div input {
  display: none;
}

#cassiel-library div div div label {
  display: none;
}

#cassiel-library div div div h1 {
  font-size: 24px;
  margin: 0px;
}

.list-group-item.tinted {
  background-color: white;
  border: 1px dotted teal;
  margin-bottom: 10px;
  padding: 14px;
  padding-bottom: 5px;
  direction: rtl;
}

span {
  cursor: pointer;
}

.list-group-item-close-btn {
  position: absolute;
  right: 16px;
  font-size: 33px;
  color: red;
}

#cassiel-library div div .list-group-item-close-btn {
  display: none;
}

div#cassiel-library {
  padding: 10px;
}

.home-page-section {
  display: grid;
  grid-template-columns: 1fr;
  /* padding: 10px; */
  /* margin: 5px; */
  /* border: 1px teal double; */
  min-height: 660px;
}

.home-page-section-title {
  text-transform: capitalize;
  font-size: 24px;
}

.home-page-section-content {
  text-align: center;
}

.cassiel-dropplace {
  border: 1px dashed gray;
  min-height: 50px;
  margin: 5px;
  padding: 5px 0;
}

.cassiel-droped-record-removebtn {
  float: right;
  margin-right: 10px;
  color: red;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
}

.cassiel-droped-record {
  cursor: pointer;
}

.home-page-section-input {
  outline: none;
  background: transparent;
  border: none;
  text-align: center;
  padding: 5px 20px;
  cursor: pointer;
  display: block;
}

.state-2 {
  color: #f0ad4e;
}

.state-1 {
  color: #00a550;
}

.state-0 {
  color: #1e90ff;
}

.square-green {
  height: 10px;
  width: 10px;
  background-color: #00a550;
}

.square-yellow {
  height: 10px;
  width: 10px;
  background-color: #f0ad4e;
}

.square-blue {
  height: 10px;
  width: 10px;
  background-color: #1e90ff;
}

.hint {
  margin-bottom: 0px;
  font-size: 16px;
  margin-block-start: 0;
  margin-top: -5px;
  margin-right: 5px;
  margin-left: 5px;
}
</style>
